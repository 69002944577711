<script setup>
import { onMounted } from "vue";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import router from "@/router";

const url = new URL(window.location.href.replace("/#", ""));
const token = url.searchParams.get("token");

onMounted(() => {
  if (token) {
    // Set bearer token
    JwtService.saveToken(token);
    ApiService.setHeader();

    router.push({
      name: "projectWorkflows"
    });
  } else {
    router.push({
      name: "login"
    });
  }
});
</script>

<template>
  <div class="d-flex align-center justify-center w-100">
    <b-spinner class="align-middle" type="grow" variant="primary"></b-spinner>
  </div>
</template>

<style lang="scss" scoped>
.spinner-grow {
  width: 3.5rem;
  height: 3.5rem;
}
</style>
